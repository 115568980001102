.ant-input,
.ant-input-number-input {
  @apply placeholder-coolGray-400 font-medium;
}

.ant-calendar-picker-input {
  @apply h-10 w-80 px-3 py-2 text-coolGray-800 border border-coolGray-300;
}

.ant-calendar-picker-icon,
.ant-calendar-picker .anticon-close-circle {
  @apply absolute top-4 h-5 w-5;
  right: 1rem !important;

  svg {
    @apply h-full w-full;
  }
}

.ant-time-picker-input {
  @apply text-coolGray-800 font-medium;
}

.ant-time-picker-panel {
  min-width: 112px;
}
