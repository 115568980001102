.customCheckbox {
  border-color: var(--checkbox-border);
  background-color: var(--checkbox-bg);
}

.customCheckbox:hover:not(:disabled) {
  border-color: var(--checkbox-border);
}

.customIcon {
  color: var(--checkbox-border);
}

.input:focus-visible + div {
  outline: 1px solid var(--checkbox-border, #15020c);
}
