.container {
  @apply flex flex-col;
  :global(.rc-slider) {
    @apply p-0;
  }
  :global(.rc-slider-mark-text) {
    @apply text-xs text-coolGray-800;
  }
  :global(.rc-slider-handle) {
    @apply bg-white w-5 h-5 border-coolGray-300 opacity-100;
  }
  :global(.rc-slider-handle-dragging) {
    @apply border-coolGray-300 shadow-none !important;
  }
  :global(.rc-slider-track) {
    @apply bg-maroon-400 h-3;
  }
  :global(.rc-slider-rail) {
    @apply bg-coolGray-200 h-3;
  }
  :global(.rc-slider-dot-active) {
    @apply border-maroon-200;
  }
  :global(.rc-slider-disabled) {
    @apply bg-transparent opacity-75;
  }
}
