#sl-generator-animation {
  background-color: rgba(255, 255, 255, 0.9);
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 999999;
  flex: auto;

  .animation-wrap {
    margin: auto;
  }

  .animation-image img {
    height: 100px;
    text-align: center;
    display: inline-block;
  }

  .animation-text {
    font-size: 20px;
    margin-top: 20px;
    @apply text-maroon-200;
  }

  .animation-progress-bar .ant-progress-outer {
    width: 750px;
    margin-top: 15px;

    .ant-progress-inner .ant-progress-bg {
      @apply bg-maroon-200;
    }
  }
}
