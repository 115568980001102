.highlight {
  z-index: 10;
  position: relative;
  display: inline-block;
  line-height: 1.5rem;
}

.sentence {
  line-height: 1.625rem;
}

.sentence > span {
  position: relative;
  margin: 0 3px;
}

.highlight::before,
.highlight::after {
  content: ' ';
  position: absolute;
  background-color: inherit;
  width: 3px;
  top: 0;
  bottom: 0;
}

.highlight::before {
  left: -3px;
}

.highlight::after {
  right: -3px;
}
