.impressed {
  fill: theme('colors.yellow.300');
  stroke: theme('colors.yellow.500');
}

.helpful {
  fill: theme('colors.gold.500');
  stroke: theme('colors.gold.500');
}

.curious {
  fill: theme('colors.pink.200');
  stroke: theme('colors.pink.200');
}

.excited {
  fill: theme('colors.base.300');
  stroke: theme('colors.base.300');
}

.surprising {
  fill: theme('colors.coral.300');
  stroke: theme('colors.coral.300');
}

.appreciative {
  fill: theme('colors.maroon.400');
  stroke: theme('colors.maroon.400');
}

.urgent {
  fill: theme('colors.gold.400');
  stroke: theme('colors.gold.400');
}

.impressed:hover,
.helpful:hover,
.curious:hover,
.excited:hover,
.surprising:hover,
.appreciative:hover,
.urgent:hover {
  opacity: 0.8;
  transition: opacity 0.2s ease;
}
