.container {
  :global(.rc-dropdown-menu .rc-dropdown-menu-item-selected) {
    @apply relative;
  }
  :global(.rc-dropdown-menu-item-selected:after) {
    @apply text-maroon-400 top-4;
  }
  :global(.rc-dropdown-menu .rc-dropdown-menu-item-selected:after) {
    content: '\e613';
    font-family: 'anticon';
    font-weight: bold;
    position: absolute;
    right: 16px;
  }
}
