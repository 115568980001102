:root {
  --search-space: 1rem;
}

.templateTabs {
  :global(.rc-tabs-nav) {
    max-width: calc(100vw - 42.7rem - var(--search-space));
  }

  :global(.rc-tabs-tab) {
    background-color: transparent;
  }

  @media only screen and (min-width: 1024px) {
    :global(.rc-tabs-nav) {
      max-width: calc(100vw - 52.8rem - var(--search-space));
    }
  }

  @media only screen and (min-width: 1200px) {
    :global(.rc-tabs-nav) {
      max-width: calc(100vw - 51.8rem - var(--search-space));
    }
  }

  @media only screen and (min-width: 1600px) {
    :global(.rc-tabs-nav) {
      max-width: calc(100vw - 53rem - var(--search-space));
    }
  }
}

.templatesConfigPanel {
  width: 50%;

  @media only screen and (min-width: 1200px) {
    width: 29.063rem;
  }
  @media only screen and (min-width: 1600px) {
    width: 31.25rem;
  }
}

.search {
  width: 100%;
  padding-left: var(--search-space);
  max-width: calc(8.75rem + var(--search-space));

  @media only screen and (min-width: 1024px) {
    max-width: calc(12.375rem + var(--search-space));
  }
  @media only screen and (min-width: 1200px) {
    max-width: calc(19rem + var(--search-space));
  }
  @media only screen and (min-width: 1600px) {
    max-width: calc(15.75rem + var(--search-space));
  }
}
