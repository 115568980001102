.ant-form {
  legend {
    @apply mb-0;
    @apply border-b-0;
  }

  .select-radio,
  .select-checkbox {
    @apply flex;
    @apply flex-wrap;
    margin: -6px;

    & > label {
      margin: 6px;
    }
  }

  legend .ant-form-item-control {
    @apply leading-3;
  }

  .ant-checkbox:hover::after,
  .ant-checkbox-wrapper:hover .ant-checkbox::after {
    @apply invisible;
  }

  .select-radio {
    .ant-checkbox-wrapper {
      .ant-checkbox-inner {
        @apply rounded-full;
        @apply relative;
        @apply border-coolGray-300;
        @apply transition-none;
        &::after {
          @apply transform-none;
          @apply transition-none;
          @apply inline-block;
          @apply rounded-full;
          @apply animate-none;
          @apply border-none;
          @apply bg-white;
          width: 6px;
          height: 6px;
          position: absolute;
          display: inline-block;
          top: 4px;
          left: 4px;
        }
      }
    }
  }

  .ant-checkbox-wrapper {
    @apply px-3;
    @apply py-2;
    @apply border;
    @apply transition-none;
    @apply bg-white;
    @apply border-coolGray-300;
    @apply ml-2;
    @apply leading-normal;

    &.ant-checkbox-wrapper-checked {
      @apply bg-orange-50;
      @apply border-maroon-400;
    }
    .ant-checkbox {
      @apply p-0;
    }

    span:nth-child(2) {
      @apply p-0 pl-3 text-coolGray-800;
    }
  }

  fieldset legend {
    @apply inline-block;
    @apply relative;
    @apply w-auto;

    .ant-form-item {
      @apply mb-2;
    }

    .guidingText {
      @apply static;
      @apply w-auto;
      @apply max-w-full;
    }
  }
}

#campaign-step-2 {
  & .ant-form-item-control {
    .ant-form-item-children > input,
    & .ant-select,
    & > .ant-input,
    & > .ant-input-number-input {
      @apply max-w-md h-10 text-coolGray-800;
      @apply focus:outline-none focus:border-maroon-400 focus:shadow-none;
      @apply focus:bg-maroon-400 focus:bg-opacity-10;
    }
  }
  & .ant-form-item-children .ant-cascader-picker {
    @apply max-w-md text-coolGray-800;
    @apply focus:outline-none focus:border-maroon-400 focus:shadow-none;
    @apply focus:bg-maroon-400 focus:bg-opacity-10;

    & input {
      @apply h-10;
    }
  }

  & legend .ant-form-item-children .ant-cascader-picker {
    @apply max-w-full;
  }

  .ant-select {
    @apply placeholder-coolGray-800;
  }

  .error {
    @apply mt-2 leading-6 text-red-400;
  }
}

// step 2 offer end date
.ant-calendar-picker + p .ant-input {
  @apply w-8 mt-2;
}

.ant-form-item-label {
  line-height: 1.5;
  @apply mb-1 font-medium whitespace-normal text-left;

  label {
    @apply text-coolGray-800;
  }

  // hide label colon
  label::after {
    content: '';
  }
}

.ant-form-item-control {
  @apply leading-none;
}
