.rc-dropdown-menu {
  @apply py-2 text-coolGray-800 border-coolGray-200 shadow-md;

  li.rc-dropdown-menu-item {
    @apply pr-6 pl-4 py-2 text-sm text-coolGray-800;

    &:hover {
      @apply bg-coolGray-100;
    }
  }

  .rc-dropdown-menu-item-divider {
    @apply my-2;
  }
}
