.cell,
.cell * {
  @apply overflow-hidden;
}

.table {
  :global(div[role='row']):hover {
    .rowActions {
      @apply visible;
    }
  }
}

.rowActions {
  @apply invisible;
}

.common {
  &:first-child {
    :global(.child-cell) {
      @apply pl-6 !important;
    }
  }
  &:last-child {
    :global(.child-cell) {
      @apply pr-6 !important;
    }
  }
}
