.span1 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 0px;
  transition: transform 0.2s linear;
  transform-origin: left;
  width: auto;
  animation: animation1 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) 0s infinite;
}

.span2 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  top: 0px;
  transition: transform 0.2s linear;
  transform-origin: left;
  width: auto;
  animation: animation2 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) 1.15s infinite;
}

@keyframes animation1 {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes animation2 {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
