:global(.rc-time-picker) {
  @apply w-full;
}

:global(.rc-time-picker):has(button) > :global(.rc-time-picker-clock) {
  @apply hidden;
}

:global(.rc-time-picker):has(input[disabled]) {
  @apply opacity-50;
}

:global(.rc-time-picker-input),
:global(.rc-time-picker-panel-input) {
  width: inherit;
  @apply px-4 py-3 bg-gold-40 h-10 rounded-sm font-normal text-base-700 text-sm outline-none;
}

:global(.rc-time-picker-input):not([disabled]) {
  @apply border-gold-400 hover:border-gold-500;
}

:global(.rc-time-picker-input):is([disabled]) {
  @apply bg-gold-40;
}

:global(.rc-time-picker-panel-input) {
  @apply border-base-700;
}

:global(.rc-time-picker-input)::placeholder {
  @apply font-normal text-gold-700 text-sm;
}

:global(.rc-time-picker-panel-input)::placeholder {
  @apply font-normal text-gold-700 text-sm;
}

:global(.rc-time-picker-panel-input-wrap) {
  @apply border-0 p-0 w-full;
}

:global(.rc-time-picker-panel-inner) {
  padding-top: 3px;
  @apply border-0 bg-transparent;
}

:global(.rc-time-picker-panel-combobox) {
  @apply mt-1 border-1 border-gold-200 flex justify-between bg-gold-50 text-base-700 font-normal text-base;
}

:global(.rc-time-picker-panel-select) {
  @apply overflow-hidden border-0 flex-1;
  scrollbar-width: thin;
}

:global(.rc-time-picker-panel-select):hover {
  @apply overflow-auto;
}

:global(li.rc-time-picker-panel-select-option-selected) {
  @apply bg-yellow-200;
}

:global(.rc-time-picker-panel-select li) {
  @apply font-normal text-sm p-0 text-center;
}

:global(.rc-time-picker-panel-select li):hover {
  @apply bg-gold-300;
}

:global(.rc-time-picker-panel-narrow) {
  max-width: 123px;
}
