.cards {
  @apply absolute;
  top: 45%;
}

.cards .card:nth-child(1) {
  transform: translate(-50%, -50%) rotate(-16.66deg);
}

.cards .card:nth-child(2) {
  transform: translate(-50%, -50%) rotate(-8.33deg);
}

.cards .card:nth-child(3) {
  transform: translate(-50%, -50%) rotate(0deg);
}

.cards .card:nth-child(4) {
  transform: translate(-50%, -50%) rotate(8.33deg);
}

.cards .card:nth-child(5) {
  transform: translate(-50%, -50%) rotate(16.66deg);
}

.cards .card:nth-child(6) {
  transform: translate(-50%, -50%) rotate(26deg);
}

.card {
  @apply absolute top-1/2 left-1/2 h-72 w-64 p-6 shadow-xl bg-white;
  transform-origin: center 120%;
}
