:global(.rc-tabs-nav-operations-hidden) {
  @apply hidden;
}

:global(.rc-tabs) {
  @apply overflow-visible;
}

:global(.rc-tabs-dropdown) {
  z-index: 111;
}

.inPage {
  border: none !important;
  @apply flex-1;
  @apply bg-gold-40;

  :global(.rc-tabs-nav-list) {
    @apply flex justify-between flex-1;
  }
  :global(.rc-tabs-nav) {
    @apply w-min;
    max-width: 690px;
  }
  :global(.rc-tabs-tabpane) {
    @apply bg-gold-40;
  }
  :global(.rc-tabs) {
    @apply bg-gold-40;
  }
  :global(.rc-tabs-tab-btn) {
    @apply cursor-pointer px-4 py-1 bg-gray-50 select-none;
  }
  :global(.rc-tabs-tab) {
    @apply text-base font-medium text-coolGray-400  mr-0 pt-4  px-0;
  }
  :global(.rc-tabs-tab-disabled *) {
    @apply cursor-not-allowed;
  }

  :global(.rc-tabs-tab-active) {
    @apply text-maroon-400;
    @apply border-b-2 border-solid border-maroon-400;
  }
  :global(.rc-tabs-ink-bar-animated) {
    @apply hidden;
  }
  :global(.rc-tabs-nav-wrap) {
    @apply relative flex;
    top: 1px;
  }
  :global(.rc-tabs-nav-wrap:after) {
    @apply border-none;
  }
  :global(.rc-tabs-nav-operations > .rc-tabs-nav-more) {
    margin: 16px !important;
    @apply border-none bg-none;
  }
}

.modal {
  border: none !important;
  @apply flex-1 bg-white flex flex-col items-stretch h-full;
  :global(*) {
    @apply box-border;
  }
  :global(.rc-tabs-tab-disabled *) {
    @apply cursor-not-allowed;
  }
  :global(.rc-tabs-tab) {
    @apply bg-transparent;
  }
  :global(.rc-tabs-content-holder) {
    @apply flex flex-col flex-1 min-h-0;
  }
  :global(.rc-tabs-content) {
    @apply h-full;
  }
  :global(.rc-tabs-nav-operations) {
    @apply hidden;
  }
  :global(.rc-tabs-nav:before) {
    /* @apply shadow;*/
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    border-bottom: 1px solid;
    @apply border-coolGray-300;
    content: '';
  }
  :global(.rc-tabs-tab-btn) {
    width: 418px;
  }
  :global(.rc-tabs-ink-bar) {
    @apply bg-white z-10 opacity-100 transition-none;
    height: 1px;
    width: 417px !important; /* prevent to overlap the border */
  }
  :global(.rc-tabs-tab-active .rc-tabs-tab-btn) {
    @apply text-maroon-400 font-bold text-base;
  }

  :global(:not(.rc-tabs-tab-active) > .rc-tabs-tab-btn) {
    @apply text-coolGray-500 font-bold text-base;
  }

  :global(.rc-tabs-nav-list) {
    counter-reset: list-number;
  }
  :global(.rc-tabs-tab .rc-tabs-tab-btn) {
    @apply py-5 font-bold text-base border-r-1 border-solid border-coolGray-300 relative;
    &:before {
      counter-increment: list-number;
      content: counter(list-number) '.';
      @apply pr-4 ml-6;
    }
  }
}

.filter {
  border: none !important;
  @apply flex-1;
  @apply bg-gold-40;

  :global(.rc-tabs-tab) {
    @apply bg-gold-40 text-sm font-medium text-coolGray-500 px-4 py-2 mx-1 capitalize;
  }
  :global(.rc-tabs-nav) {
    max-width: 850px;
    @media only screen and (max-width: 1600px) {
      max-width: 600px;
    }
    @media only screen and (max-width: 1200px) {
      max-width: 330px;
    }
    @media only screen and (max-width: 1024px) {
      max-width: 210px;
    }
  }
  :global(.rc-tabs-tab:hover) {
    @apply bg-gray-100;
  }
  :global(.rc-tabs-tab-btn) {
    @apply leading-5;
  }
  :global(.rc-tabs-tab-disabled *) {
    @apply cursor-not-allowed text-coolGray-300;
  }

  :global(.rc-tabs-tab-active) {
    @apply text-coolGray-800;
    @apply bg-gray-100;
  }
  :global(.rc-tabs-ink-bar-animated) {
    @apply hidden;
  }
  :global(.rc-tabs-nav-wrap) {
    @apply relative;
    top: 1px;
  }
  :global(.rc-tabs-nav-wrap:after) {
    @apply border-none;
  }
  :global(.rc-tabs-nav-operations > .rc-tabs-nav-more) {
    @apply border-none bg-transparent text-sm font-medium text-coolGray-500;
  }
  :global(.rc-tabs-tabpane > div > div) {
    @media only screen and (min-width: 1920px) {
      grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
    }
  }
  :global(.rc-tabs-dropdown-menu-item) {
    @apply capitalize;
  }
  :global(.rc-tabs-dropdown) {
    z-index: 10000;
  }
}
