.ant-tag {
  @apply mt-1 px-2 py-1 font-rob text-xs font-normal text-coolGray-800;
  @apply border border-maroon-400 bg-orange-50 cursor-pointer;

  &.add-tag {
    @apply text-coolGray-400 border-coolGray-400;
  }

  .anticon {
    @apply relative;
    top: -2px;
  }

  .anticon-plus {
    @apply pr-1;

    svg {
      fill: #9ca3af;
    }
  }

  .anticon-close {
    @apply pl-1;

    svg {
      fill: #fdaf5b;
    }
  }
}

.ant-select-show-search {
  @apply mt-1;
}
