:global(.rc-tabs-nav-operations-hidden) {
  @apply hidden;
}

:global(.highcharts-button-box) {
  stroke: none;
  fill: none;
}

.tabs {
  border: none !important;
  :global(.rc-tabs-nav-list) {
    @apply grid grid-flow-col gap-2;
  }
  :global(.rc-tabs-nav) {
    max-width: 690px;
  }
  :global(.rc-tabs-tab-btn) {
    line-height: inherit;
    @apply cursor-pointer px-2 py-3;
  }

  :global(.rc-tabs-tab) {
    @apply bg-gold-50 text-gold-700 rounded-sm text-sm font-medium;
  }

  :global(.rc-tabs-tab-active) {
    @apply text-base-700 font-medium bg-yellow-200;
  }

  :global(.rc-tabs-ink-bar-animated) {
    display: none;
  }
  :global(.rc-tabs-nav-wrap) {
    @apply relative;
    top: 1px;
  }

  :global(.rc-tabs-nav-wrap:after) {
    border: none;
  }

  :global(.rc-tabs-nav-operations > .rc-tabs-nav-more) {
    margin: 16px !important;
    background: none;
    border: none;
  }
}

:global(.rc-tabs-dropdown) {
  max-width: 450px;
  @apply text-coolGray-800 border-coolGray-100 shadow-md;
}

:global(.rc-tabs-dropdown-menu-item) {
  @apply pr-6 pl-4 py-2 bg-white;
}

:global(.rc-tabs-dropdown-menu-item-active) {
  @apply bg-coolGray-100;
}

.emptyTab :global(.rc-tabs-tab) {
  min-height: 48px;
  min-width: 140px;
}

.widget {
  box-shadow: 0 4px 8px 0 rgba(14, 31, 53, 0.08),
    0 1px 4px 0 rgba(14, 31, 53, 0.12);
}
