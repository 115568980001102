.break1 {
  flex: none;
  @apply relative -ml-4;

  @media only screen and (min-width: 900px) and (max-width: 1319px) {
    flex-grow: 1;
    flex-basis: 100%;
    height: 0;
  }

  @media only screen and (min-width: 1320px) {
    flex: none;
  }
}

.break2 {
  flex-grow: 1;
  flex-basis: 100%;
  @apply relative -ml-4 h-0;

  @media only screen and (min-width: 900px) {
    flex: none;
  }
}
