.steps {
  :global(.rc-steps-item-custom .rc-steps-item-icon > .rc-steps-icon) {
    top: 3px;
    @apply text-current;
  }

  :global(.rc-steps-item-title) {
    @apply text-current font-medium ml-2;
  }

  :global(.rc-steps-item-tail) {
    @apply left-3;
  }

  :global(.rc-steps-item-tail::after) {
    @apply bg-coolGray-300;
  }
}
