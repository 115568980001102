.iconWhite {
  circle {
    fill: white; /* TODO fill-white once we upgrade to TailwindCss 3 */
  }
}

.iconDisabled {
  circle {
    fill: #d1d5db; /* TODO fill-coolGray-300 once we upgrade to TailwindCss 3 */
  }
}
