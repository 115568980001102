.gridForm {
  grid-template-columns: minmax(auto, 288px) minmax(auto, 288px);
}

@screen md {
  .gridForm {
    grid-template-columns: minmax(auto, 288px) minmax(auto, 288px) minmax(
        auto,
        496px
      );
  }
}
