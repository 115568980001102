.menu {
  :global(.szh-menu__item--open) {
    @apply bg-coolGray-100;
    &::after {
      @apply text-maroon-400;
    }
  }
}

:global(.szh-menu-button) {
  outline: none !important;
}

.menuItem {
  /* TODO after moving to Tailwind V3 use tailwind instead */
  &::after {
    @apply text-coolGray-300;
  }

  &:hover {
    &::after {
      @apply text-maroon-400;
    }
  }
}
