.timePicker {
  @apply w-full;
  max-width: 128px;
}

.timePicker :global(input::placeholder) {
  @apply text-blueGray-400 !important;
}

.timePicker :global(input) {
  padding-right: 2rem;
}

.timePicker :global(.ant-time-picker-icon) {
  @apply w-6 h-6 -mt-3 right-2;
}

.timePicker :global(.anticon-close-circle) {
  right: 13px !important;
}
