.integrationDatePicker {
  @apply mr-4;
}

.integrationDatePicker :global(.ant-calendar-picker-input) {
  @apply w-48;
}

.integrationDatePickerSmall :global(.ant-calendar-picker-input) {
  @apply w-44;
}

.integrationDatePicker :global(.ant-calendar-picker-icon) {
  right: 8px !important;
}

.integrationDatePicker :global(.anticon-close-circle) {
  right: 10px !important;
}

.integrationDatePicker :global(input::placeholder) {
  @apply text-blueGray-400 !important;
}

.integrationTimePicker {
  @apply w-full;
  max-width: 128px;
}

.integrationTimePicker :global(input::placeholder) {
  @apply text-blueGray-400 !important;
}

.integrationTimePicker :global(input) {
  padding-right: 2rem;
}

.integrationTimePicker :global(.ant-time-picker-icon) {
  @apply w-6 h-6 -mt-3 right-2;
}

.integrationTimePicker :global(.anticon-close-circle) {
  right: 13px !important;
}

.integrationSelect {
  @apply w-88;
}

.integrationSelect {
  :global(.anticon-close-circle) {
    @apply -top-1;
    right: 0 !important;
  }

  :global(.ant-form-item-label) {
    @apply text-sm font-bold whitespace-normal text-left;
  }

  :global(.ant-form-item-children) {
    @apply leading-none;
  }

  :global(.ant-form-item-control) {
    @apply leading-none;
  }
}

.integrationSelectError {
  :global(.ant-form-item-children) {
    @apply text-red-400 leading-none;
  }

  :global(.ant-form-item-label > label) {
    @apply text-red-400;
  }

  :global(.ant-select-selection) {
    background-color: #fffcfc;
    @apply border-red-500;
  }

  :global(.ant-select-selection__placeholder) {
    @apply text-red-400;
  }
}
