@import '~antd/dist/antd.less';
@import '~antd/lib/style/color/colorPalette.less';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  button {
    @apply focus:outline-none;
  }
}

@import './styles/select.less';
@import './styles/form.less';
@import './styles/input.less';
@import './styles/tag.less';
@import './styles/table.less';
@import './styles/tabs.less';
@import './styles/dropdown.less';
@import './styles/drawer.less';
@import './styles/generatorAnimation.less';
@import './styles/highcharts.css';
@import './styles/menu.less';

@primary-color: #901356;@input-border-color: #d1d5db;@select-border-color: #d1d5db;@component-background: #f9fafb;@select-multiple-item-disabled-color: #bfbfbf;@select-item-selected-color: #1f2937;@input-height-base: 40px;