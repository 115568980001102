.ul {
  list-style: none;
  counter-reset: item;
}

.li {
  position: relative;
  counter-increment: item;

  &:before {
    @apply text-coolGray-500 text-base;
    content: counter(item) '.';
    min-width: 1.5rem;
  }
}
