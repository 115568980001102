.gaugeWrapper {
  @apply relative h-full;
}

.gaugeWrapper::after,
.gaugeWrapper::before {
  content: ' ';
  @apply block absolute border border-dashed border-coolGray-300;
  z-index: 2;
}

.gaugeWrapper::after {
  @apply w-full h-px top-1/2 left-0;
}

.gaugeWrapper::before {
  @apply w-px h-full top-0 left-1/2 border-l;
}

.low {
  @apply absolute -right-9;
  top: calc(50% - 10px);
}

.fair {
  @apply absolute -bottom-6;
  left: calc(50% - 12px);
}

.high {
  @apply absolute -left-9;
  top: calc(50% - 10px);
}
