.centered {
  :global(.rc-dialog) {
    @apply h-screen flex items-center justify-center my-0;
  }
}

.withMargin {
  :global(.rc-dialog-body) {
    @apply p-6;
  }
}

.withoutMargin {
  :global(.rc-dialog-body) {
    @apply p-0;
  }
}

.large {
  background-color: rgba(31, 41, 55, 0.7);
  height: 100vh;
  @apply pb-0 px-0 md:px-40  h-full overflow-y-hidden;

  :global(.rc-dialog) {
    @apply m-0 overflow-x-auto overflow-y-hidden relative w-auto;
    min-width: 768px;
  }

  :global(.rc-dialog-content) {
    @apply h-full;
  }

  :global(.rc-dialog-body) {
    @apply h-full pb-4;
  }
}

.responsive {
  :global(.rc-dialog) {
    @apply m-auto w-auto;
  }
  :global(.rc-dialog-content) {
    @apply w-full m-6;
  }
}

.modal {
  :global(.rc-dialog) {
    /* full screen - top margin */
    @apply mt-6;
    height: calc(100vh - 24px);
  }
  :global(.rc-dialog-close) {
    @apply z-70 top-6 right-6 text-coolGray-400 opacity-100 focus:outline-none focus-visible:outline-black;
  }
  :global(.rc-dialog-footer) {
    @apply flex justify-end;
  }
}
