.SetUpExperiment .SingleDatePicker,
.SetUpExperiment .SingleDatePickerInput,
.SetUpExperiment .DateInput,
.SetUpExperiment .DateInput_input {
  @apply w-full;
}
.SetUpExperiment .SingleDatePickerInput {
  @apply flex;
}
.SetUpExperiment .SingleDatePickerInput_calendarIcon {
  @apply ml-auto;
}
