:global(.unifiedFlowOptimizeIntegrationSection [class^='Drawer_footer']) {
  @apply bg-gold-100;
  @apply border-t-1;
  @apply border-gold-400;
  @apply mx-12;
  @apply mb-10;
  @apply pt-4;
  @apply pr-0;
}

:global(.unifiedFlowOptimizeIntegrationSection > [class^='Drawer_content']) {
  @apply p-6;
}
