div.shadowInnerTop:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px;
  z-index: 9999;
  box-shadow: 0 4px 6px -2px rgba(0, 0, 0, 0.1),
    0 2px 2px -1px rgba(0, 0, 0, 0.06);
}

div.shadowInnerBottom:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 10px;
  z-index: 9999;
  box-shadow: 0 -4px 6px -2px rgba(0, 0, 0, 0.1),
    0 -2px 2px -1px rgba(0, 0, 0, 0.06);
}
