.ant-select-selection-selected-value {
  color: #1f2937;
  font-weight: 500;
}

.ant-select-selection__placeholder {
  @apply text-coolGray-400 font-medium;
}

.ant-select-arrow {
  svg {
    fill: #4a5568;
  }
}

.ant-select-clear-icon {
  svg {
    position: relative;
    top: -0.25rem;
    right: 0.25rem;
    height: 20px;
    width: 20px;
  }
}

.ant-select-disabled .ant-select-selection-selected-value[style] {
  opacity: 0.2 !important;
}

.borderless-tags .ant-select-selection__choice {
  @apply border-none;
}

.ant-select-dropdown-menu {
  @apply border border-coolGray-200 border-solid;
}

.ant-select-dropdown-menu-item {
  @apply py-2 px-4 text-coolGray-800 font-medium;
}

.ant-select-dropdown-menu-item-selected {
  @apply bg-white font-normal text-maroon-200;
}

.ant-select-focused .ant-select-selection {
  background-color: rgba(255, 247, 237, 0.25) !important;
}
