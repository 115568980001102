.experimentSettingsFormContent {
  @apply flex flex-col;

  :global(.SingleDatePicker) {
    @apply w-full;
  }

  :global(.SingleDatePickerInput) {
    @apply flex w-full;
  }

  :global(.DateInput) {
    @apply w-full;
  }

  :global(.DateInput_input) {
    @apply w-full;
  }

  :global(.SingleDatePickerInput_calendarIcon) {
    @apply ml-auto;
  }
}
