.campaignResultsTable {
  :global(.ant-checkbox-inner) {
    @apply w-5 h-5;
  }
  :global(.ant-checkbox-inner::after) {
    width: 7px;
    height: 11px;
  }
}

:global(.highcharts-button-box) {
  stroke: none;
  fill: none;
}

.tabs {
  :global(.custom-nav-operations-hidden) {
    @apply hidden;
  }
  :global(.custom-nav-list) {
    @apply flex justify-center;
  }
  :global(.custom-tab-btn) {
    @apply cursor-pointer px-4 py-2;
  }
  :global(.custom-tab) {
    @apply border border-solid border-blueGray-200 text-coolGray-500;
    margin-left: -1px;
  }
  :global(.custom-tab-active) {
    @apply bg-blueGray-200 text-coolGray-800 font-medium;
  }
  :global(.custom-ink-bar-animated) {
    display: none;
  }
}
