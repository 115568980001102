.overlay {
  @apply opacity-100;
  :global(.rc-tooltip-inner) {
    @apply bg-white  border border-gray-300 border-solid;
  }
  :global(.rc-tooltip-arrow) {
    transform: translate(0, -12px);
    border-right-color: white;
    border-width: 15px 15px 15px 0;
    left: -4px;
  }
}
