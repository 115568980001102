.container {
  :global(.DateRangePicker),
  :global(.DateRangePickerInput),
  :global(.SingleDatePicker),
  :global(.SingleDatePickerInput),
  :global(.DateInput),
  :global(.DateInput_input) {
    @apply h-10 bg-gold-40;
  }

  :global(.DateRangePickerInput),
  :global(.SingleDatePickerInput) {
    @apply border-gold-400 rounded-sm bg-gold-40 flex;
  }

  :global(.DateRangePickerInput:hover),
  :global(.SingleDatePickerInput:hover) {
    @apply border-gold-500;
  }

  :global(.DateRangePickerInput:focus-within),
  :global(.SingleDatePickerInput:focus-within) {
    @apply border-base-700;
  }

  :global(.DateRangePickerInput:active),
  :global(.SingleDatePickerInput:active) {
    @apply border-base-700;
  }

  :global(.DateRangePickerInput__disabled),
  :global(.SingleDatePickerInput__disabled) {
    @apply opacity-50 hover:border-gold-400 cursor-not-allowed;
  }

  :global(.DateRangePickerInput),
  :global(.SingleDatePickerInput),
  :global(.DateInput),
  :global(.DateInput_input) {
    @apply bg-transparent outline-none;
  }

  :global(.DateInput_input) {
    @apply px-4 py-3 font-normal text-base-700 text-sm border-transparent bg-transparent;
  }

  :global(.DateInput_input::placeholder) {
    @apply text-gold-700 not-italic;
  }

  :global(.DateInput_input:disabled) {
    @apply cursor-not-allowed;
  }

  :global(.SingleDatePickerInput > button:first-child) {
    @apply inline-flex m-0 pl-4 pr-0 focus:outline-none focus-visible:outline-black;
  }

  :global(.SingleDatePickerInput > button) {
    @apply inline-flex m-0 pl-0 pr-4 focus:outline-none focus-visible:outline-black;
  }

  :global(.SingleDatePickerInput_clearDate):not(:global(.SingleDatePickerInput_clearDate__hide))
    + :global(.SingleDatePickerInput_calendarIcon) {
    @apply hidden;
  }

  :global(.SingleDatePickerInput:has(.SingleDatePickerInput_clearDate__hide):not(:has(.SingleDatePickerInput_calendarIcon:first-child))) {
    @apply pr-0;
  }

  :global(.SingleDatePickerInput_calendarIcon),
  :global(.SingleDatePickerInput_clearDate) {
    @apply inline-flex m-0 py-3 focus:outline-none focus-visible:outline-black;
  }

  :global(.SingleDatePickerInput_clearDate:hover) {
    @apply bg-transparent;
  }

  :global(.SingleDatePicker_picker),
  :global(.DateRangePicker_picker) {
    @apply z-10 border border-gold-200 rounded-sm pb-0 -mt-4;
  }

  :global(.CalendarMonth_caption > strong) {
    @apply text-base-700 font-normal text-base;
  }

  :global(.DateInput_fang) {
    @apply hidden;
  }

  :global(.DayPicker__horizontal) {
    @apply bg-gold-50;
  }

  :global(.DayPicker_transitionContainer),
  :global(.CalendarMonth),
  :global(.CalendarMonthGrid) {
    @apply bg-transparent;
  }

  :global(.CalendarDay) {
    @apply text-gold-700 font-normal text-sm bg-gold-50 border-transparent;
  }

  :global(.CalendarDay:hover) {
    @apply bg-gold-300 text-base-700;
  }

  :global(.CalendarDay__blocked_out_of_range) {
    @apply opacity-30 cursor-not-allowed text-base-700;
  }

  :global(.CalendarDay__blocked_out_of_range:hover) {
    @apply bg-transparent text-base-700;
  }

  :global(.CalendarDay__selected) {
    @apply bg-yellow-200 text-base-700;
  }

  :global(.CalendarDay__selected:hover) {
    @apply bg-gold-300 text-base-700 border-0;
  }

  :global(.CalendarDay),
  :global(.CalendarDay__blocked_out_of_range),
  :global(.CalendarDay__blocked_out_of_range:hover) {
    @apply border-0;
  }

  :global(.DayPicker_weekHeader_li > small) {
    @apply text-gold-700 text-xs font-normal;
  }

  :global(.DateRangePickerInput) {
    @apply flex items-center relative;
  }

  :global(.DateRangePickerInput_calendarIcon) {
    @apply inline-flex m-0 py-3 pl-4 pr-0 focus:outline-none focus-visible:outline-black;
  }

  :global(.DateRangePickerInput_clearDates) {
    @apply inline-flex m-0 py-3 pl-0 pr-4 focus:outline-none focus-visible:outline-black;
  }

  :global(.CalendarDay__selected_span) {
    @apply bg-yellow-100;
  }

  :global(.DateRangePickerInput_arrow) {
    @apply flex;
  }
}
