@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Outfit';
    src: url('./fonts/Outfit/Outfit-Light.woff2') format('woff2');
    font-display: swap;
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: 'Outfit';
    src: url('./fonts/Outfit/Outfit-Regular.woff2') format('woff2');
    font-display: swap;
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Outfit';
    src: url('./fonts/Outfit/Outfit-Medium.woff2') format('woff2');
    font-display: swap;
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: 'Outfit';
    src: url('./fonts/Outfit/Outfit-Bold.woff2') format('woff2');
    font-display: swap;
    font-weight: 700;
    font-style: normal;
  }
  @font-face {
    font-family: 'Relais';
    src: url('./fonts/Relais/JRelais-Medium.woff2') format('woff2');
    font-display: swap;
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Source Code Pro';
    src: url('./fonts/SourceCodePro/SourceCodePro-Regular.ttf.woff2')
      format('woff2');
    font-display: swap;
    font-weight: 400;
    font-style: normal;
  }
}

* {
  font-family: 'Outfit', Arial;
  box-shadow: none !important;
}
.text-2xl,
.text-3xl,
.text-4xl,
.text-5xl,
.text-6xl,
.md\:text-2xl,
.md\:text-3xl,
.md\:text-4xl,
.md\:text-5xl,
.md\:text-6xl,
.sm\:text-2xl,
.sm\:text-3xl,
.sm\:text-4xl,
.sm\:text-5xl,
.sm\:text-6xl {
  /* Georgia */
  font-family: 'Relais', Times !important;
  font-weight: 400 !important;
}

input,
.text-xs,
.text-sm,
.text-base,
.text-lg,
.text-xl {
  font-family: 'Outfit', Arial !important;
}

body {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* ./src/index.css */

@layer utilities {
  /* unlike break-words, this utility works without fixed width */
  .break-word {
    word-break: break-word;
  }
}

input[autocomplete='false'] + div[data-lastpass-icon-root] {
  display: none;
}

input[data-lpignore='true'] + div[data-lastpass-icon-root] {
  display: none;
}
