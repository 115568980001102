:root {
  --toastify-color-success: #22c55e;
  --tostify-color-error: #ef4444;
  --tostify-color-info: #3b82f6;
  --tostify-color-warning: #facc15;
}

.Toastify__toast-container {
  @apply w-auto max-w-125;
}

.Toastify__toast {
  @apply px-4;
  min-height: 12px;
}

.Toastify__toast-body {
  @apply font-medium;
}

.Toastify__close-button {
  @apply text-white self-center w-4 outline-none;
}

.Toastify__toast-icon {
  margin-inline-end: 16px;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  @apply text-coolGray-800;
}
