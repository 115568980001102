.filepond--list {
  @apply hidden;
}

.filepond--wrapper {
  @apply bg-no-repeat bg-center bg-cover p-2;
  background-image: url("data:image/svg+xml,%3csvg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%' height='100%' fill='none' rx='2' ry='2' stroke='%23EFE2C8' stroke-width='2' stroke-dasharray='8%2c8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.filepond--root {
  @apply m-0;
}

.filepond--list-scroller {
  @apply hidden;
}

.filepond--panel-root {
  @apply bg-gold-100 rounded-sm;
}

.filepond--drop-label {
  @apply absolute h-full;
}
