.rc-tabs-dropdown {
  @apply bg-blueGray-50 border-blueGray-200 cursor-pointer;

  .rc-tabs-dropdown-menu-item:hover {
    @apply bg-gray-100;
  }
  z-index: 100;
}

.rc-tabs-nav-measure-ping-left::before,
.rc-tabs-nav-wrap-ping-left::before {
  border: none !important;
}
