.container {
  :global(.DateRangePicker),
  :global(.DateRangePickerInput),
  :global(.SingleDatePicker),
  :global(.SingleDatePickerInput),
  :global(.DateInput),
  :global(.DateInput_input) {
    @apply h-10;
  }

  :global(.DateRangePicker),
  :global(.SingleDatePicker) {
    @apply bg-gray-50;
  }

  :global(.DateRangePickerInput),
  :global(.SingleDatePickerInput) {
    @apply border-coolGray-300;
  }

  :global(.SingleDatePickerInput),
  :global(.DateRangePickerInput),
  :global(.DateInput),
  :global(.DateInput_input) {
    @apply bg-transparent;
  }

  :global(.DateInput) {
    @apply w-24;
  }

  :global(.DateInput) ~ :global(.DateInput) {
    @apply mr-2;
  }

  :global(.DateInput_input) {
    @apply p-2 text-sm text-coolGray-800 font-medium border-transparent bg-transparent;
  }

  :global(.DateRangePickerInput__disabled),
  :global(.SingleDatePickerInput__disabled),
  :global(.DateInput_input__disabled) {
    @apply cursor-not-allowed not-italic text-coolGray-400;
  }

  :global(.SingleDatePickerInput__disabled)
    :global(.SingleDatePickerInput_calendarIcon) {
    @apply cursor-not-allowed;
  }

  :global(.CalendarDay__selected_span),
  :global(.CalendarDay__hovered_span:hover),
  :global(.CalendarDay__hovered_span) {
    @apply bg-maroon-200 text-white border-white;
  }

  :global(.CalendarDay__selected) {
    @apply bg-maroon-400 text-white border-white;
  }

  :global(.CalendarDay__selected:hover),
  :global(.CalendarDay__selected_span:hover) {
    @apply bg-maroon-200 text-white border-white;
  }

  :global(.SingleDatePickerInput_calendarIcon) {
    @apply p-2 focus:outline-none focus-visible:outline-black;
  }

  :global(.SingleDatePicker_picker) {
    @apply z-10 border border-coolGray-200 border-solid;
    @apply pb-4; /* give space to display the calendar when scrolling is needed */
  }

  :global(.DateRangePicker_picker) {
    @apply border border-coolGray-200 border-solid;
  }

  :global(.DateRangePicker) svg:global(.DateInput_fang) {
    top: 56px !important;
  }

  :global(.SingleDatePicker) svg:global(.DateInput_fang) {
    top: 56px !important;
    @apply z-20;
  }

  :global(.SingleDatePickerInput_clearDate) {
    @apply opacity-0 absolute focus:outline-none focus-visible:outline-black;
  }

  :global(.SingleDatePickerInput__showClearDate) {
    @apply pr-0;
    :global(.SingleDatePickerInput_clearDate) {
      @apply hover:opacity-100;
    }
  }

  :global(.CalendarDay__blocked_calendar[role='button']) {
    @apply cursor-not-allowed;
  }
}
