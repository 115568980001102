.container {
  :global(.rc-slider-mark-text) {
    @apply text-xs text-coolGray-800;
  }

  :global(.rc-slider-handle) {
    @apply bg-maroon-400;
  }
  :global(.rc-slider-track) {
    @apply bg-maroon-200;
  }
  :global(.rc-slider-rail) {
    @apply bg-red-50;
  }
  :global(.rc-slider-handle) {
    @apply border-maroon-200;
  }
  :global(.rc-slider-dot-active) {
    @apply border-maroon-200;
  }
}
