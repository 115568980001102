.checkbox {
  :global(.rc-checkbox-inner) {
    @apply border w-8 h-8;
  }
  :global(.rc-checkbox-inner::after) {
    width: 7px;
    height: 14px;
    left: 12px;
    top: 5px;
  }
  :global(.rc-checkbox-checked .rc-checkbox-inner::after) {
    @apply visible;
  }
  :global(:not(.rc-checkbox-checked) .rc-checkbox-inner::after) {
    @apply invisible;
  }
  :global(.red .rc-checkbox-inner) {
    @apply bg-red-400 border-red-600;
  }
  :global(.orange .rc-checkbox-inner) {
    @apply bg-maroon-600 border-maroon-700;
  }
  :global(.amber .rc-checkbox-inner) {
    @apply bg-amber-400 border-amber-600;
  }
  :global(.emerald .rc-checkbox-inner) {
    @apply bg-emerald-400 border-emerald-600;
  }
  :global(.blue .rc-checkbox-inner) {
    @apply bg-blue-400 border-blue-600;
  }
  :global(.indigo .rc-checkbox-inner) {
    @apply bg-indigo-400 border-indigo-600;
  }
  :global(.violet .rc-checkbox-inner) {
    @apply bg-violet-400 border-violet-600;
  }
}
