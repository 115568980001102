.ant-table {
  .ant-checkbox-indeterminate .ant-checkbox-inner::after {
    @apply hidden;
  }

  .ant-table-fixed {
    @apply bg-blueGray-50;
  }
  
  .ant-table-tbody > tr td {
    @apply text-coolGray-700 text-right;
  }

  .ant-table-fixed-left .ant-table-fixed,
  .ant-table-fixed-left .ant-table-thead > tr > th {
    @apply bg-white text-left;
  }

  .ant-table-fixed-left .ant-table-tbody > tr.ant-table-row-selected td {
    @apply bg-white;
  }

  .ant-table-fixed-left .ant-table-tbody > tr td {
    @apply bg-white text-left;
  }

  .ant-table-thead > tr > th {
    padding: 16px 24px !important;
    @apply font-bold;
  }

  .ant-table-tbody > tr td {
    padding: 16px 24px !important;
  }

  // row hover
  .ant-table-thead>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
  .ant-table-tbody>tr.ant-table-row-hover:not(.ant-table-expanded-row)>td, 
  .ant-table-thead>tr:hover:not(.ant-table-expanded-row)>td, 
  .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td {
    @apply bg-emerald-50;
  }

  .ant-table-thead > tr > th {
    word-break: unset;
    @apply text-right;
  }

  .ant-table-body[style] {
    @apply overflow-x-auto !important;
  }
}
