.checkbox {
  &:hover {
    :global(.ant-checkbox-inner) {
      border-color: currentColor;
    }
    :global(.ant-checkbox-checked::after) {
      border: none;
    }
  }
  :global(.ant-checkbox) {
    color: inherit;
  }
  :global(.ant-checkbox:hover) {
    opacity: 0.5;
  }
  :global(.ant-checkbox-inner) {
    color: inherit;
    background-color: none;
    border-color: currentColor !important;
  }

  /* Add background wiht opacity */
  :global(.ant-checkbox-inner:before) {
    background-color: currentColor;
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  :global(.ant-checkbox-checked) {
    :global(.ant-checkbox-inner) {
      color: inherit;
      background-color: currentColor;
      border-color: currentColor;
    }
  }
}
